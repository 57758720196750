<template>
  <div class="register  left-float-top ml-30">
    <div class="flex-1">
      <el-steps style="width: 100%" :active="active" align-center finish-status="success">
        <el-step title="网站注册" :description="description1"></el-step>
        <el-step title="启动建设" :description="description2"></el-step>
        <el-step title="认证申请" :description="description3"></el-step>
        <el-step title="材料初审" :description="description4"></el-step>
        <el-step title="专家审核" :description="description5"></el-step>
        <el-step title="现场审核" :description="description6"></el-step>
        <el-step title="专家投票" :description="description7"></el-step>
        <el-step title="通过认证" :description="description8"></el-step>
      </el-steps>

      <div style="margin-top: 50px;padding-left: 60px;">
        <div v-if="auditResult.result=='WAIT'" ><div style="margin-bottom: 10px;">注册信息审核状态：<span style="color: red;">待审核</span></div></div>
        <div v-if="auditResult.result=='ADOPTED'" ><div style="margin-bottom: 10px;">注册信息审核状态：<span style="color: red;">通过</span></div></div>
        <div  v-if="auditResult.result=='UNADOPTED'"><div style="margin-bottom: 10px;">注册信息审核状态：<span style="color: red;">未通过</span> ，审核不通过的原因：<span style="color: red;">{{auditResult.cause}}</span>。请检查机构名称，登记码，过期时间是否与许可证名称一致，<a @click="editMessage">点击编辑信息</a>重新更改，并上传许可证图片。</div></div>
        <div style="padding: 10px; border:1px solid black;border-top-left-radius: 15px;border-top-right-radius: 15px;text-align: center;background-color: #20558B;color: white;">以机构名义注册并审核通过，激活启用数据填报平台6个月后,才可以申请认证</div>
        <el-row>
          <el-col class="left-style" :span="8"><div style="float: right;padding-right: 30px;">执业许可证机构名称:</div></el-col>
          <el-col class="right-style" :span="16">
            <el-row>
              <el-col :span="16">{{organ.name}}</el-col>
              <el-col :span="4"><a style="color: red" @click="editMessage">编辑信息</a></el-col>
<!--              <el-col :span="4"><a style="color: red" @click="changePassword">修改密码</a></el-col>-->
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col class="left-style" :span="8"><div style="float: right;padding-right: 30px;">账号名称:</div></el-col>
          <el-col class="right-style" :span="16"><div>{{organ.loginName }}</div></el-col>
        </el-row>
        <el-row>
          <el-col class="left-style" :span="8"><div style="float: right;padding-right: 30px;">地址:</div></el-col>
          <el-col class="right-style" :span="16"><div>{{ organ.district ? organ.district.fullName : '' }} {{ organ.address }}</div></el-col>
        </el-row>
        <el-row>
          <el-col class="left-style" :span="8"><div style="float: right;padding-right: 30px;">法人:</div></el-col>
          <el-col class="right-style" :span="16"><div>{{organ.legal}}</div></el-col>
        </el-row>
        <el-row>
          <el-col class="left-style" :span="8"><div style="float: right;padding-right: 30px;">相关信息:</div></el-col>
          <el-col class="right-style" :span="16"><div>床位:{{organ.bed}}，职工: {{organ.employees}}，{{organ.levelName}}</div></el-col>
        </el-row>
<!--        <el-row>-->
<!--          <el-col class="left-style" :span="8"><div style="float: right;padding-right: 30px;">胸痛中心试运时间:</div></el-col>-->
<!--          <el-col class="right-style" :span="16"><div> </div></el-col>-->
<!--        </el-row>-->
        <el-row>
          <el-col class="left-style" :span="8"><div style="float: right;padding-right: 30px;">认证负责人:</div></el-col>
          <el-col class="right-style" :span="16"><div>{{organ.leaderPost}}: {{organ.leaderName}}，{{organ.leaderPhone}}，{{organ.leaderEmail}},</div></el-col>
        </el-row>
        <el-row>
          <el-col class="left-style" :span="8"><div style="float: right;padding-right: 30px;">联络员:</div></el-col>
          <el-col class="right-style" :span="16"><div>{{organ.linkmanPost}}: {{organ.linkmanName}}，{{organ.linkmanPhone}}，{{organ.linkmanEmail}}</div></el-col>
        </el-row>
<!--        <div style="color: red;"><div style="margin: 10px 0;">您上次登录时间为: 2024/1/30<span style="padding-left: 40px;">首次登录累计登录次数: 1次</span></div></div>-->
        <div style="margin-top: 20px;padding: 10px; border:1px solid black;border-top-left-radius: 15px;border-top-right-radius: 15px;text-align: center;background-color: #20558B;color: white;">请点击上方编辑信息->上传《医疗机构执业许可证》</div>
        <el-row style="background-color: #F5F8FF">
          <el-col class="left-style" :span="8"><div>上传时间</div></el-col>
          <el-col class="right-style" :span="8"><div>文件名称</div></el-col>
          <el-col class="right-style" :span="8"><div>操作</div></el-col>
        </el-row>
        <el-row style="background-color: #F5F8FF" v-for="(item,index) in certifiedMaterials" :key="index">
          <el-col class="left-style" :span="8"><div>{{item.time}}</div></el-col>
          <el-col class="right-style" :span="8"><div>{{item.name}}</div></el-col>
          <el-col class="right-style" :span="8"><div><a @click="checkDetail(index)">查看</a></div></el-col>
          <el-dialog
              title="文件"
              :visible.sync="dialogVisible"
              width="50%">
               <span>
                  <div class="demo-image__placeholder">
                      <div class="block">
                         <span class="demonstration"></span>
                             <el-image :src="src"></el-image>
                      </div>
                  </div>
               </span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
              </span>
          </el-dialog>
        </el-row>
      </div>
    </div>

  </div>
</template>

<script>
import { getOrganDetail } from '@/api'
export default {
  name: "OrganInformation",
  data(){
    return{
      active:0,
      description1:'',
      description2:'',
      description3:'',
      description4:'',
      description5:'',
      description6:'',
      description7:'',
      description8:'',
      certification:[],
      organ: {},
      dialogVisible: false,
      src:'',
      certifiedMaterials:[],
      auditResult:{},
    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem('cpis-web_userInfo'))) {
      this.organId = JSON.parse(localStorage.getItem('cpis-web_userInfo')).id
      this.reqOrganDetail()
    }

  },
  methods:{
    async reqOrganDetail() {
      const self=this;
      const res = await getOrganDetail(this.organId)
      self.organ = res.biz;
      let lastApproves=self.organ.lastApproves.filter(item => item.certificationStatus == 'NEW');
      self.auditResult={result:lastApproves[0].result,cause:lastApproves[0].cause}
      self.certifiedMaterials.push({time:self.organ.ctime,name:'许可证'})
      self.certification=self.organ.certification;
      self.active=self.organ.certification.length;
      self.description1=self.certification[0] ? self.certification[0].time.substring(0, 10) : '';
      self.description2=self.certification[1] ? self.certification[1].time.substring(0, 10) : '';
      self.description3=self.certification[2] ? self.certification[2].time.substring(0, 10) : '';
      self.description4=self.certification[3] ? self.certification[3].time.substring(0, 10) : '';
      self.description5=self.certification[4] ? self.certification[4].time.substring(0, 10) : '';
      self.description6=self.certification[5] ? self.certification[5].time.substring(0, 10) : '';
      self.description7=self.certification[6] ? self.certification[6].time.substring(0, 10) : '';
      self.description8=self.certification[7] ? self.certification[7].time.substring(0, 10) : '';
    },
    checkDetail:function (index){
      this.dialogVisible=true;
      // this.src=this.organ.certifiedMaterials[index].file.url;
      this.src=this.organ.licenseFrontPathUrl;


    },
    editMessage:function (){
      this.$router.push({path:'/auth/organ/message/pectoralgia',query:{id: this.organ.id}})
    },
    changePassword:function(){
      this.$router.push({path:'/auth/organ/message/password',query:{id: this.organ.id}})
    }
  }
}
</script>

<style scoped>

.left-style{
  border-left:1px solid black;
  border-right:1px solid black;
  border-bottom:1px solid black;
  border-left:1px solid black;
  padding: 10px;
  height: 40px;
}
.right-style{
  border-right:1px solid black;
  border-bottom:1px solid black;
  border-left:1px solid black;
  border-left: none;
  padding: 10px;
  height: 40px;
}
</style>